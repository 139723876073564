// ! THIS API MAY DEFFER TO YOURS, CONSIDER CHANGING IT !
// export const API = 'https://genimi-admin.bakeli.tech/api/v1/'
// export const API_URL = "http://192.168.68.241:8000/api/v1/";
// export const API_URL = "api/v1/";
// export const API_URL = "https://backend.genimi.io/api/v1/";
export const API_URL = "https://www.api-diagnostic.bakeli.tech/api/v1/";
// process.env.REACT_APP_API_STATUS === "production"
//   ? process.env.REACT_APP_API_URL_PROD
//   : process.env.REACT_APP_API_URL_RECETTE;
// export const API = "http://192.168.68.155:8000/api/v1/"
// "https://recette-genimi.fewnu.app/api/v1/";
export const TTS_API_URL = "https://text-to-speech.genimi.io/";
