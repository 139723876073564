import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useGetUserInfo from "hooks/useGetUserInfo";
import { AuthProvider } from "context/AuthContext";
import { DrawerProvider } from "context/DrawerContext";
import { ToastContainer } from "react-toastify";
import ModalInstall from "components/ModalInstall";
import Drawer from "components/Drawer";

function Root() {
  const { userId } = useGetUserInfo();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleInstall = () => {
    setOpened(false);

    // Show the prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // console.log("User accepted the A2HS prompt");
        } else {
          // console.log("User dismissed the A2HS prompt");
        }
        // console.log({ choiceResult });
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    // console.log({ env: process.env.REACT_APP_API_URL_PROD });
    async function actionInstall(e) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      setOpened(true);
      // Update UI to notify the user they can add to home screen
      // btnInstall.current.style.display = "block";
    }

    window.addEventListener("beforeinstallprompt", actionInstall);
  }, []);

  return (
    <AuthProvider>
      <DrawerProvider>
        <div className="max-w-[500px] mx-auto overflow-hidden bg-red-300 relative">
          {userId === 1340 ? (
            <div className="fixed top-0 w-full left-0 px-2 py-[1px] text-[8px] flex justify-center font-bold text-white bg-danger z-50 opacity-90">
              DEMO TEST
            </div>
          ) : null}
          <Outlet />
          <Drawer />
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <ModalInstall
            opened={opened}
            onInstall={handleInstall}
            onClosed={() => setOpened(false)}
          />
        </div>
      </DrawerProvider>
    </AuthProvider>
  );
}

export default Root;
