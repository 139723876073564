import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { TTS_API_URL } from "routes/api";
import {
  ChevronLeft,
  ChevronRight,
  Microphone,
  Point,
} from "tabler-icons-react";
const API_AUDIO = TTS_API_URL;

const data = [
  {
    niveau: 1,
    id: 1,
    data: [
      {
        id: 1,
        title: "Lire les mots suivants",
        choices: [
          "Papa",
          "Maman",
          "Tete",
          "Bonbon",
          "Chien",
          "Mouton",
          "Telephone",
        ],
        moyenne: 20,
        seuil: 15,
      },
      {
        id: 2,
        title: "Lire les Phrases suivantes",
        choices: [
          "Je suis a l'ecole",
          "Maman prepare le repas",
          "On va parler de la philosophie",
          "Pourquoi suis-je ici",
        ],
        moyenne: 20,
        seuil: 15,
      },
      {
        id: 3,
        title: "Lire les nombres suivants",
        choices: ["10", "20", "16", "11", "18", "19"],
        moyenne: 20,
        seuil: 17,
      },
    ],
  },
  // { niveau: 2, data: [{}] },
];

function DignostiquePageNew() {
  const [currentNiveau, setCurrentNiveau] = useState(0);
  const [currentExercice, setCurrentExercice] = useState(0);
  const [currentWord, setCurrentWord] = useState(0);

  const [correction, setCorrection] = useState([]);
  const [finised, setFinised] = useState(false);

  const { startRecording, stopRecording, recordingBlob, isRecording } =
    useAudioRecorder();

  useEffect(() => {
    if (!recordingBlob) return;
    // console.log("lalal");
    addAudioElement(recordingBlob);
    // recordingBlob will be present at this point after 'stopRecording' has been called
  }, [recordingBlob]);

  const addAudioElement = async (blob) => {
    // console.log({
    //   blob,
    //   recordingBlob,
    //   mediaRecorder,
    //   recordingTime,
    //   isPaused,
    // });
    if (!blob) {
      return;
    }

    if (
      currentWord <
      data[currentNiveau].data[currentExercice].choices.length - 1
    ) {
      setCurrentWord(currentWord + 1);
    } else if (
      currentWord ===
      data[currentNiveau].data[currentExercice].choices.length - 1
    ) {
      if (currentExercice < data[currentNiveau].data.length - 1) {
        setCurrentExercice(currentExercice + 1);
        setCurrentWord(0);
        // console.log({ correction });
      } else if (currentExercice === data[currentNiveau].data.length - 1) {
        if (currentNiveau < data.length - 1) {
          setCurrentNiveau(currentNiveau + 1);
          setCurrentExercice(0);
          setCurrentWord(0);
        } else {
          setFinised(true);
        }
      }
    }

    // Créer un objet FormData et ajouter le blob
    const formData = new FormData();
    formData.append("upload_file", blob, "audio.mp3");

    try {
      // Envoyer le fichier à l'API
      const res = await axios.post(
        `${API_AUDIO}transcription?lang=fr`,
        formData
      );

      if (res?.status === 200) {
        // Faire une requete de comparaison
        const scoreRes = await axios.post(
          `${API_AUDIO}compare_texts?source=${data[currentNiveau].data[currentExercice].choices[currentWord]}&target=${res?.data}`,
          {}
        );

        if (scoreRes?.status === 200) {
          const score = scoreRes?.data?.score;
          const justification = scoreRes?.data?.llm_score?.justification;

          let copyCorrection = correction;
          const findExerciceIndex = copyCorrection.findIndex(
            (item) => item?.id === data[currentNiveau].data[currentExercice].id
          );

          const setData = {
            id: currentWord + 1,
            word: data[currentNiveau].data[currentExercice].choices[
              currentWord
            ],
            transcription: res?.data,
            score,
            justification,
          };

          if (findExerciceIndex !== -1) {
            if (
              !copyCorrection[findExerciceIndex]?.data.find(
                (item) => item?.id === setData.id
              )
            ) {
              copyCorrection[findExerciceIndex].data = [
                ...copyCorrection[findExerciceIndex]?.data,
                { ...setData },
              ];
            } else {
              copyCorrection[findExerciceIndex].data = [
                ...copyCorrection[findExerciceIndex]?.data.filter(
                  (item) => item?.id === setData.id
                ),
                { ...setData },
              ];
            }
            setCorrection([...copyCorrection]);
          } else {
            copyCorrection = [
              ...copyCorrection,
              { id: data[currentNiveau].data[currentExercice].id, data: [] },
            ];

            const _findExerciceIndex = copyCorrection.findIndex(
              (item) =>
                item?.id === data[currentNiveau].data[currentExercice].id
            );

            if (_findExerciceIndex !== -1) {
              if (
                !copyCorrection[_findExerciceIndex]?.data.find(
                  (item) => item?.id === setData.id
                )
              ) {
                copyCorrection[_findExerciceIndex].data = [
                  ...copyCorrection[_findExerciceIndex]?.data,
                  { ...setData },
                ];
              } else {
                copyCorrection[_findExerciceIndex].data = [
                  ...copyCorrection[_findExerciceIndex]?.data.filter(
                    (item) => item?.id === setData.id
                  ),
                  { ...setData },
                ];
              }
              setCorrection([...copyCorrection]);
            }
          }
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du fichier audio", error);
    }
  };

  return finised ? (
    <div className="p-2">
      {correction.map((correct) => (
        <div className="my-2 bg-gray p-2 rounded-lg" key={correct?.id}>
          <div className="">Exercice ({correct?.id})</div>
          <div className="px-2">
            {correct?.data.map((item) => (
              <div className="my-3" key={item?.id}>
                <div className="flex items-center gap-3">
                  <p className="underline text-sm">Text </p> {"=>"}
                  <p className="">{item?.word} </p>
                </div>
                <div className="flex items-center gap-3">
                  <p className="underline text-sm">Transcription </p> {"=>"}
                  <p className="">{item?.transcription} </p>
                </div>
                <div className="flex items-center gap-3">
                  <p className="underline text-sm">Score </p> {"=>"}
                  <p className="text-[green]">
                    {item?.score * 100}
                    {"%"}
                  </p>
                </div>
                {/* <div className="flex items-center gap-3">
                  <p className="underline text-sm">Justification </p> {"=>"}
                  <p className="">{item?.justification} </p>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      ))}
      <button
        onClick={() => {
          setCurrentNiveau(0);
          setCurrentExercice(0);
          setCurrentWord(0);
          setFinised(false);
        }}
        className="bg-gray p-2 rounded-full px-4"
      >
        Recommencer
      </button>
    </div>
  ) : (
    <div className="p-2">
      <h3 className="font-bold text-xl text-center">
        Niveau {data[currentNiveau].niveau}
      </h3>
      <div className="h-1 w-full bg-gray my-4" />
      <div className="">
        <div className="flex my-4 items-center gap-4">
          <p className="underline text-danger">
            Exercice ({currentExercice + 1}):
          </p>
          <p className="text-center text-lg font-[500]">
            {data[currentNiveau].data[currentExercice].title}
          </p>
        </div>

        <div className="flex items-center justify-between gap-6 bg-[#9992]">
          <button
            className={
              "p-4 disabled:opacity-20" +
              (currentWord > 0 ? "bg-[#9994] active:bg-[#9999] " : "bg-[#9990]")
            }
            onClick={() => {
              if (currentWord > 0) {
                setCurrentWord(currentWord - 1);
              }
            }}
            disabled
          >
            <ChevronLeft />
          </button>
          <h3 className="font-bold text-xl">
            {data[currentNiveau].data[currentExercice].choices[currentWord]}
          </h3>
          <button
            className={
              "p-4  disabled:opacity-20" +
              (currentWord <
              data[currentNiveau].data[currentExercice].choices.length - 1
                ? "bg-[#9994] active:bg-[#9999]"
                : "bg-[#9990]")
            }
            onClick={() => {
              // console.log(
              //   currentWord,
              //   data[currentNiveau].data[currentExercice].choices.length
              // );
              if (
                currentWord <
                data[currentNiveau].data[currentExercice].choices.length - 1
              ) {
                setCurrentWord(currentWord + 1);
              }
            }}
            disabled
          >
            <ChevronRight />
          </button>
        </div>

        <div className="fixed bottom-0 p-6 w-full flex justify-center">
          {/* <AudioRecorder
            onRecordingComplete={addAudioElement}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            // downloadOnSavePress={true}
            downloadFileExtension="mp3"
            showVisualizer
          /> */}
          <div
            className="flex items-center rounded-full justify-center bg-primary text-[#fff] px-4 py-4 focus:bg-secondary cursor-pointer"
            onPointerUpCapture={(e) => {
              stopRecording();
            }}
            onPointerDownCapture={async (e) => {
              startRecording();
            }}
            data-tooltip-target={"elementId"}
          >
            {isRecording && <Point color="red" className="animate-ping" />}
            <Microphone color={isRecording ? "red" : "#fff"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DignostiquePageNew;
