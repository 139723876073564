import { styled } from "styled-components";
import { X } from "tabler-icons-react";
import DiagnosticConsigne from "../pages/diagnostic/components/DiagnosticConsigne";
import ExerciceProgressIndicator from "../pages/diagnostic/components/ExerciceProgressIndicator";

const Card = styled.div`
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 0 0.6rem;
  width: auto;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const ProgressIndicator = ({
  current,
  data,
  randomeImage,
  selected,
  handleRemoveWord,
  selectedTextSpeach,
  suggestionManquante,
  onCheckInput,
  suggestionInputActive,
}) => {
  return (
    <div className="text-sm" style={{ marginTop: 0 }}>
      {/* New progress indicator */}
      <ExerciceProgressIndicator
        currentStep={current + 1}
        totalSteps={data?.length}
      />

      <DiagnosticConsigne title={data[current]?.titre} />

      {data[current]?.type === "organisation" ? (
        <div
          className=""
          style={{
            display: "flex",
            gap: 10,
            flexWrap: "wrap",
            marginTop: 20,
            paddingBottom: 5,
            borderBottom: "1px solid gray",
          }}
        >
          {selected.length === 0 ? (
            <span style={{ color: "gray" }}>Cliquer sur un element ...</span>
          ) : (
            selected.map((item, index) => (
              <div
                className="bg-[#9997] px-2 py-1 rounded-lg cursor-pointer text-sm relative"
                key={index}
                onClick={() => handleRemoveWord(item)}
              >
                {item?.split("-")[0]}
                <div
                  className=""
                  style={{
                    position: "absolute",
                    width: 10,
                    height: 10,
                    // backgroundColor: "red",
                    top: -2,
                    right: -4,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <X size={10} color="red" />
                </div>
              </div>
            ))
          )}
        </div>
      ) : data[current]?.type === "lettreManquante" ? (
        <div
          className=""
          style={{
            display: "flex",
            gap: 10,
            flexWrap: "wrap",
            marginTop: 20,
            paddingBottom: 5,
            // borderBottom: "1px solid gray",
          }}
        >
          {data[current]?.correction_dp.map((item, index) =>
            data[current]?.choices.some(
              (manq) => manq?.toLowerCase() === item?.toLowerCase()
            ) ? (
              <input
                key={index + "." + item}
                value={
                  suggestionManquante?.find((choice) => choice?.key === index)
                    ?.choice || ""
                }
                max={1}
                readOnly
                onClick={() => {
                  onCheckInput(index);
                }}
                // className={`w-[2rem] h-[2rem] font-bold ${
                //   suggestionInputActive === index
                //     ? "border-solid"
                //     : "border-dotted"
                // } border-primary focus:outline-none border-[3px] bg-gray rounded-lg flex text-center text-2xl items-center justify-center uppercase`}

                className={`w-10 h-9 text-center ${
                  suggestionInputActive === index
                    ? "border-primary border-4"
                    : "border-secondary border"
                } rounded-lg focus:border-secondary focus:outline-none font-bold  border-primary flex text-center text-xl items-center justify-center uppercase `}
              />
            ) : (
              <Card
                className="w-[1rem]"
                key={index}
                // onClick={() => handleRemoveWord(item)}
              >
                {item}
              </Card>
            )
          )}
        </div>
      ) : data[current]?.type === "alphabet" ? (
        <div
          className=""
          style={{
            display: "flex",
            gap: 10,
            flexWrap: "wrap",
            marginTop: 20,
            paddingBottom: 5,
            borderBottom: "1px solid gray",
          }}
        >
          {selected.length === 0 ? (
            <span style={{ color: "gray" }}></span>
          ) : (
            selected.map((item, index) => (
              <Card
                className=""
                key={index}
                onClick={() => handleRemoveWord(item)}
              >
                {item}
                <div
                  className=""
                  style={{
                    position: "absolute",
                    width: 10,
                    height: 10,
                    // backgroundColor: "red",
                    top: -5,
                    right: -5,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <X size={10} color="red" />
                </div>
              </Card>
            ))
          )}
        </div>
      ) : data[current]?.type === "dictee" ? (
        <div
          className=""
          style={{
            display: "flex",
            gap: 10,
            flexWrap: "wrap",
            marginTop: 20,
            paddingBottom: 5,
            borderBottom: "1px solid gray",
          }}
        >
          {selectedTextSpeach.length === 0 ? (
            <span style={{ color: "gray" }}></span>
          ) : (
            selectedTextSpeach.map((item, index) => (
              <Card
                className=""
                key={index}
                // onClick={() => handleRemoveWord(item)}
              >
                {item?.value}
                <div
                  className=""
                  style={{
                    position: "absolute",
                    width: 10,
                    height: 10,
                    // backgroundColor: "red",
                    top: -5,
                    right: -5,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <X size={10} color="red" />
                </div>
              </Card>
            ))
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ProgressIndicator;
